function isEmpty(obj) {
  if (typeof obj == 'undefined' || (!obj && typeof obj != 'undefined' && obj != 0) || obj == null) {
    return true
  }
  for (let i in obj) {
    return false
  }
  if (typeof obj === 'number') {
    return false
  }
  return true
}

function isNotEmpty(obj) {
  return !isEmpty(obj)
}

/**
 * 获取url参数
 */
function getUrlParameterByName(name, url) {
  if (!url) {
    return ''
  }
  name = name.replace(/[[\]]/g, '\\$&')
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

function parseQuery(query) {
  var reg = /([^=&\s]+)[=\s]*([^&\s]*)/g
  var obj = {}
  while (reg.exec(query)) {
    obj[RegExp.$1] = RegExp.$2
  }
  return obj
}

/**
 * 拼接url参数
 */
function urlParamCombine(obj) {
  let param = '?'
  for (let key in obj) {
    obj[key] = obj[key] == null ? '' : obj[key]
    if (typeof obj[key] == 'object') {
      for (let k in obj[key]) {
        param += k + '=' + obj[key][k] + '&'
      }
    } else {
      param += key + '=' + obj[key] + '&'
    }
  }
  return param.substr(0, param.length - 1)
}

/**
 * 拼接url参数，首字母升序排序（从小到大）
 */
function concatSortParams(queryParams) {
  // 获取排序key
  let sortkeys = allKeysSorted(queryParams)
  // 生成query数组
  let codeParis = []
  let queryParis = []
  let i = sortkeys.length
  while (i--) {
    codeParis.push(sortkeys[i] + '=' + queryParams[sortkeys[i]])
    queryParis.push(sortkeys[i] + '=' + queryParams[sortkeys[i]]) //+encodeURIComponent(queryParams[sortkeys[i]]))
  }

  let query = queryParis.join('&')
  return query
}

/**
 * 获取字典所有key，并按首字母升序排序（从小到大）
 *
 * @param dic 字典
 * @return 排序的keys
 */
function allKeysSorted(dic) {
  let sortkeys = []
  for (let key in dic) {
    // key = key.toLowerCase();
    sortkeys.push(key)
  }

  sortkeys = sortkeys.sort(function (s, t) {
    var a = s.toLowerCase()
    var b = t.toLowerCase()
    if (a < b) return -1
    if (a > b) return 1
    return 0
  })

  sortkeys.reverse()
  return sortkeys
}

/** json字符串转换为object实体 */
function stringToObject(str) {
  if (isEmpty(str)) {
    return {}
  }
  return JSON.parse(decodeURIComponent(str))
}

function objectToString(obj) {
  if (isEmpty(obj)) {
    return ''
  }
  return encodeURIComponent(JSON.stringify(obj))
}

/**
 * 深拷贝（假定不存在undefine和函数对象）
 * @param {string} str
 */
function deepCopy(src) {
  return JSON.parse(JSON.stringify(src))
}

/**
 * 获取随机数；
 * @param {Number} min:开始范围;
 * @param {Number} max:结束范围;
 * @returns {Number}
 *
 * 如:获取1~10里面的随机数, random(1,10);
 *
 */
function randomRange(min, max) {
  if ('number' !== typeof min || 'number' !== typeof max) return
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function toSetUniq(arr) {
  return Array.from(new Set(arr))
}

// 防抖函数
function debounce(fn, time, triggleNow) {
  var t = null

  return function () {
    var _self = this,
      args = arguments

    if (t) {
      clearTimeout(t)
    }

    if (triggleNow) {
      var exce = !t

      t = setTimeout(function () {
        t = null
      }, time)

      if (exce) {
        fn.apply(_self, args)
      }
    } else {
      t = setTimeout(function () {
        fn.apply(_self, args)
        t = null
      }, time)
    }
  }
}

/**
 * 创建并返回一个像节流阀一样的函数，当重复调用函数的时候，最多每隔 wait毫秒调用一次该函数
 * @param func 执行函数
 * @param wait 时间间隔
 * @param options 如果你想禁用第一次首先执行的话，传递{leading: false}，
 *                如果你想禁用最后一次执行的话，传递{trailing: false}
 * @returns {Function}
 */
function throttle(func, wait, options) {
  var context, args, result
  var timeout = null
  var previous = 0
  if (!options) options = {}
  var later = function () {
    previous = options.leading === false ? 0 : new Date().getTime()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) context = args = null
  }
  return function () {
    var now = new Date().getTime()
    if (!previous && options.leading === false) previous = now
    var remaining = wait - (now - previous)
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) context = args = null
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}

/**
 *
 * 获取浏览器用户代理；
 * @returns {String}
 */
function getUserAgent() {
  let ua = ''
  try {
    if ('undefined' != typeof navigator && navigator) {
      ua = navigator.userAgent || navigator.swuserAgent || ''
    }
  } catch (e) {
    ua = ''
  }
  return ua
}

/**
 *
 * 获取设备环境；
 * @returns {Object}
 */
function getEnvironment() {
  const ua = getUserAgent()
  var isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isHarmony = /(?:Harmony)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian

  const isIos = /ip(ad|hone|od)/i.test(ua)
  const isMobile = isAndroid || isIos

  return {
    isAndroid,
    isHarmony,
    isIos,
    isMobile,
    isTablet,
    isPhone,
    isPc
  }
}

/**
 * 获取app的版本号
 * @returns {Number}
 */

function getAppVersion() {
  const ua = getUserAgent().toLowerCase()
  const i = ua.lastIndexOf('kernel')
  const version = i > -1 ? parseInt(ua.substring(i).replace('kernel', '')) : 0 //初始版本号10000
  return version
}

/**
 * 获取当前浏览器的协议版本号，通用协议适用
 */
function getKernel() {
  let userAgent_arr = navigator.userAgent.split('/')
  let client_kernell = userAgent_arr[userAgent_arr.length - 1]
  let kernell = client_kernell.replace('Kernel', '')
  if (navigator.userAgent.includes('Kernel1300')) {
    // console.log('返回1300，兼容奇妙屋iOS某些版本拼接多个kernel的问题');
    return 1300
  }
  if (kernell && client_kernell.includes('Kernel')) {
    return parseInt(kernell)
  }
  if (
    (window.AndroidService && typeof window.AndroidService.openProtocol === 'function') ||
    (window.activity && typeof window.activity.openProtocol === 'function')
  ) {
    return 1300
  }
  return 1000
}

/**
 * 是否在app环境中
 * @returns {Boolean}
 */
function isInApp() {
  const ua = getUserAgent().toLowerCase()

  if (ua.indexOf('babybus') > -1 || ua.indexOf('kernel') > -1) {
    return true
  }

  return false
}

/**
 * 是否在微信小程序环境中
 * @returns {Boolean}
 */
function isInMiniProgram() {
  const ua = getUserAgent().toLowerCase()
  if (ua.indexOf('miniprogram') > -1) {
    return true
  }

  return false
}

/**
 * 判断是否为对象；
 * @param obj:传入的对象;
 * @returns {Boolean}
 *
 */
function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}

/**
 * 对象转字符串；
 * @param {Object} obj: 传入的对象;
 * @returns {String}
 *
 */
function serialize(obj) {
  if (typeof obj == 'object') {
    return JSON.stringify(obj)
  } else {
    return obj
  }
}

/**
 * 字符串转对象；
 * @param {String} strVal: 传入的字符串;
 * @returns {Object}
 *
 */
function deserialize(strVal) {
  if (typeof strVal == 'object') {
    return strVal
  }
  // 默认传入字符串，try防止JSON解析错误
  let val = ''
  try {
    val = JSON.parse(strVal)
  } catch (error) {
    console.error('util中字符串转对象方法捕捉到的错误', error)
    val = strVal
  }
  return val
}

/**
 * @param {String} url: 传入字符串
 * @returns {Object}
 */
function parseQueryString(url) {
  var obj = {}
  var keyValue = []
  var key = '',
    value = ''
  var paraString = url.substring(url.indexOf('?') + 1, url.length).split('&')
  for (var i in paraString) {
    keyValue = paraString[i].split('=')
    key = keyValue[0]
    value = keyValue[1]
    obj[key] = value
  }
  return obj
}

// 判断是否为Number
function isNumber(obj) {
  return obj === +obj
}

// 复制到剪贴板
function copyClick(text) {
  const body = document.body
  const input = document.createElement('textarea')
  body.append(input)
  input.style.opacity = 0
  input.value = text
  input.select()
  input.setSelectionRange(0, input.value.length)
  document.execCommand('Copy')
  input.blur()
  input.remove()
}

function judgeActivityStatus(beginTime, endTime, serverTime) {
  if (!beginTime || !endTime || !serverTime) {
    console.log('活动状态有问题，不显示入口')
    return 3
  }
  if (serverTime < beginTime) {
    console.log('预热状态')
    return 0
  } else if (serverTime >= beginTime && serverTime <= endTime) {
    console.log('活动进行时')
    return 1
  } else {
    console.log('活动已结束')
    return 2
  }
}

/**
 * toFixed 精度修正，如3.565.toFixed(2)结果为3.56，修正后为3.57
 * @param {number} number 需要被四舍五入的数字;
 * @param {m} number 保留小数位数
 * @returns {String}
 *
 */
function toFixed(number, m = 0) {
  number = parseFloat(number)
  if (typeof number !== 'number') {
    throw new Error('number不是数字')
  }
  let result = Math.round(Math.pow(10, m) * number) / Math.pow(10, m)
  result = String(result)
  return result
}

function fixedFontSize(size = 1080) {
  function resizeFun() {
    const htmlEle = window.document.documentElement
    const width = Math.min(htmlEle.clientWidth, htmlEle.clientHeight, 600)
    htmlEle.style.fontSize = (width / size) * 100 + 'px'
  }
  resizeFun()
  window.addEventListener('resize', resizeFun, false)
}

export default {
  isEmpty,
  isNotEmpty,
  getUrlParameterByName,
  parseQuery,
  getEnvironment,
  urlParamCombine,
  objectToString,
  stringToObject,
  deepCopy, //深复制
  randomRange, //随机数
  toSetUniq, //数组去重
  debounce,
  throttle,
  isObject,
  concatSortParams,
  serialize,
  deserialize,
  getUserAgent,
  parseQueryString,
  isNumber,
  copyClick,
  isInApp,
  getAppVersion,
  getKernel,
  toFixed,
  judgeActivityStatus,
  fixedFontSize,
  isInMiniProgram
}
