/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RepairGoodsCategoryPageReply } from '../models';
// @ts-ignore
import { RepairGoodsCategorySelectReply } from '../models';
/**
 * RepairGoodsCategoryApi - axios parameter creator
 * @export
 */
export const RepairGoodsCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 维修商品分类列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairGoodsCategoryGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/RepairGoodsCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 维修商品分类下拉框
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairGoodsCategorySelectGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/RepairGoodsCategory/Select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairGoodsCategoryApi - functional programming interface
 * @export
 */
export const RepairGoodsCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairGoodsCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 维修商品分类列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairGoodsCategoryGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairGoodsCategoryPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairGoodsCategoryGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 维修商品分类下拉框
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiRepairGoodsCategorySelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RepairGoodsCategorySelectReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiRepairGoodsCategorySelectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepairGoodsCategoryApi - factory interface
 * @export
 */
export const RepairGoodsCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairGoodsCategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary 维修商品分类列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairGoodsCategoryGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<RepairGoodsCategoryPageReply>> {
            return localVarFp.custapiRepairGoodsCategoryGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 维修商品分类下拉框
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiRepairGoodsCategorySelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<RepairGoodsCategorySelectReply>> {
            return localVarFp.custapiRepairGoodsCategorySelectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepairGoodsCategoryApi - object-oriented interface
 * @export
 * @class RepairGoodsCategoryApi
 * @extends {BaseAPI}
 */
export class RepairGoodsCategoryApi extends BaseAPI {
    /**
     * 
     * @summary 维修商品分类列表
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairGoodsCategoryApi
     */
    public custapiRepairGoodsCategoryGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return RepairGoodsCategoryApiFp(this.configuration).custapiRepairGoodsCategoryGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 维修商品分类下拉框
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairGoodsCategoryApi
     */
    public custapiRepairGoodsCategorySelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return RepairGoodsCategoryApiFp(this.configuration).custapiRepairGoodsCategorySelectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }
}
