import Cookie from '@/utils/cookie'

export const user = {
  state: {
    headerInfo: {},
    isLogin: false,
    // 入口来源：
    // 1 小程序
    // 2 APP
    // 3 公众号
    // 4 客服
    // 5 人工客服
    entrySource: ''
  },
  getters: {
    getHeaderInfo: state => state.headerInfo
  },
  mutations: {
    SET_HEADER_INFO: (state, headerInfo = {}) => {
      try {
        console.log('------ set header cookie(Client_EncryptType, ClientHeaderInfo, Client_ProductID) ------')
        const {
          enCryptType,
          EncryptType,
          headInfo,
          ClientHeaderInfo,
          productId,
          ProductID,
          domain = '.babybus.com',
          path = '/'
        } = headerInfo

        // 小程序环境，通过客服跳转回来会存在没有携带参数的情况
        if ((enCryptType || EncryptType) && (headInfo || ClientHeaderInfo) && (productId || ProductID)) {
          Cookie.set('Client_EncryptType', enCryptType || EncryptType, {
            path,
            domain,
            expires: 172800
          })
          Cookie.set('ClientHeaderInfo', headInfo || ClientHeaderInfo, {
            path,
            domain,
            expires: 172800
          })
          Cookie.set('Client_ProductID', productId || ProductID, {
            path,
            domain,
            expires: 172800
          })
        } else {
          // 判断是否存在cookie，存在则读取 cookie内容
          const ClientEncryptType = Cookie.get('Client_EncryptType')
          const ClientHeaderInfo = Cookie.get('ClientHeaderInfo')
          const ClientProductID = Cookie.get('Client_ProductID')
          if (ClientEncryptType && ClientHeaderInfo && ClientProductID) {
            headerInfo.enCryptType = ClientEncryptType
            headerInfo.headInfo = ClientHeaderInfo
            headerInfo.productId = ClientProductID
          }
        }
      } catch (error) {
        console.error(error)
      }
      state.headerInfo = headerInfo
    },

    SET_LOGIN_STATE: (state, isLogin) => {
      state.isLogin = isLogin
    },

    SET_ENTRY_SOURCE: (state, entrySource) => {
      console.log('------ set entrySource ------', entrySource)
      state.entrySource = entrySource
    }
  },
  actions: {}
}
