import Util from '@/utils/util'
import { openProtocol } from '@bbcloud/bb-protocol'

/**
 * 关闭web-view
 */
function closeBrowser() {
  try {
    console.log('---进入方法closeWebClick---')
    openProtocol(`babybus://util/closeWebClick`)
  } catch (error) {
    console.error(error)
  }
}

/**
 * 检测终端是否安装了某个app
 * @param {Array} appNameArr app列表
 * @return {Object} 被检测的app键值对结果 可选，iOS不返回值
 */
async function checkAppIsInstalled(appNameArr) {
  appNameArr = typeof appNameArr === 'string' ? [appNameArr] : appNameArr

  try {
    let res = await openProtocol(`babybus://apk/isAppInstalled`, {
      appKeys: appNameArr
    })
    res = Util.deserialize(res)
    const data = res.status == 1 ? Util.deserialize(res.data) : {}
    return data
  } catch (error) {
    console.error(error)
  }
}

/**
 * 唤起客户端登录
 * @param {String} jsonStrEncode 支付数据
 */
function pay(jsonStrEncode) {
  console.log('---调用客户端支付---')
  // const protocol = `?Client=Vip&Type=BuyGoods&Orderbean=${jsonStrEncode}&CallbackFun=PayCallback&ParentCheckType=multiplication`; // 需要进行家长验证
  const protocol = `?Client=Vip&Type=BuyGoods&Orderbean=${jsonStrEncode}&CallbackFun=PayCallback`
  console.log('protocol: ', protocol)
  window.location.href = protocol
}

/**
 * 唤起客户端登录
 */
async function toLogin() {
  console.log('---调用babybus://account/showLogin---', window.location.href)
  try {
    let res = null
    if (Util.getEnvironment().isAndroid) {
      res = await openProtocol(`babybus://account/showLogin`)
    } else {
      res = await openProtocol(`babybus://account/showLogin`, { gotoUrl: `${window.location.href}` })
    }
    console.log('showLogin res: ', res)
    res = Util.deserialize(res)
    const loginStatue = res.status == 1 ? true : false
    return loginStatue
  } catch (error) {
    console.error(error)
  }
}

/**
 * 获取客户端登录状态
 * return {Promise}
 * 注意：返回的是promise，如需获取状态，移步至store获取（在main.js中对store设置了状态）
 */
async function isLogin() {
  try {
    console.log('---调用babybus://account/isLogin---')
    let res = await openProtocol(`babybus://account/isLogin`)
    console.log('isLogin res: ', res)
    res = Util.deserialize(res)
    const isLogin = res.status == 1 ? !!Util.deserialize(res.data) : false
    return isLogin
  } catch (error) {
    console.error(error)
  }
}

/**
 * 获取客户端vip状态
 * return {Promise}
 * 注意：返回的是promise，如需获取状态，移步至store获取（在main.js中对store设置了状态）
 */
async function getUserInfoForWeb() {
  try {
    console.log('---调用babybus://account/getUserInfoForWeb---')
    let res = await openProtocol(`babybus://account/getUserInfoForWeb`)
    console.log('getUserInfoForWeb res: ', res)
    res = Util.deserialize(res)
    const UserInfoForWeb = res.status == 1 ? Util.deserialize(res.data) : {}
    return UserInfoForWeb
  } catch (error) {
    console.error(error)
  }
}

/**
 * 获取客户端头部信息
 * @return {Promise}
 * 注意：返回的是promise，如需获取状态，移步至store获取（在main.js中对store设置了状态）
 */
async function getHeadInfo() {
  try {
    console.log('---调用babybus://account/getHeadInfo---')
    let res = await openProtocol(`babybus://account/getHeadInfo`)
    console.log('babybus://account/getHeadInfo返回信息：', res)
    res = Util.deserialize(res)
    const headInfo = res.status == 1 ? Util.deserialize(res.data) : {}
    return headInfo
  } catch (error) {
    console.error(error)
  }
}

/**
 * 获取客户端头部信息
 * @return {Promise}
 * 注意：返回的是promise，如需获取状态，移步至store获取（在main.js中对store设置了状态）
 */
async function saveImageToAlbum(url) {
  try {
    console.log('---调用babybus://util/saveImageToAlbum---')
    let imgUrl = encodeURIComponent(url)
    let res = await openProtocol(`babybus://util/saveImageToAlbum`, { url: `${imgUrl}` })
    return res
  } catch (error) {
    console.error(error)
  }
}

/**
 * 刷新用户信息
 * @return {Promise}
 */
async function refreshUserInfo() {
  try {
    console.log('---调用babybus://account/refreshUserInfo---')
    let res = await openProtocol(`babybus://account/refreshUserInfo`)
    return res
  } catch (error) {
    console.error(error)
  }
}

function isMatrixApp() {
  if (Util.getEnvironment().isAndroid) {
    let isMatrixApp = window.AndroidService && window.AndroidService.isMatrixApp()
    console.log('是否安卓矩阵产品', isMatrixApp)
    return isMatrixApp
  } else {
    return false
  }
}

export default {
  closeBrowser,
  checkAppIsInstalled,
  toLogin,
  isLogin,
  getUserInfoForWeb,
  getHeadInfo,
  pay,
  saveImageToAlbum,
  refreshUserInfo,
  isMatrixApp
}
