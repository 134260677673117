import router from './index'
import { initAuth } from './auth'
import store from '@/store'

let isInitialized = false

router.onError(error => {
  // 前端更新的时候，自动刷新网页
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    location.href = location.origin + targetPath
  }
})

router.beforeEach(async (to, from, next) => {
  // 在路由切换前设置 HTML 标题
  const pageTitle = to.meta.title
  if (pageTitle) document.title = pageTitle

  if (!isInitialized) {
    await initAuth()
    isInitialized = true
  }

  // 设置入口来源
  if (to.query.entrySource) {
    store.commit('SET_ENTRY_SOURCE', to.query.entrySource)
  }
  next()
})
