import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/WorkplaceLayout.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        meta: {
          title: '维修系统',
          navBarTitle: false
        },
        component: () => import('@/views/index/index.vue')
      },
      {
        path: '/choose_product',
        name: 'ChooseProduct',
        meta: {
          title: '选择商品'
        },
        component: () => import('@/views/applicationWays/chooseProductWay/index.vue')
      },
      {
        path: '/inquire_purchase_order',
        name: 'InquirePurchaseOrder',
        meta: {
          title: '购买订单申请',
          navBarTitle: false
        },
        component: () => import('@/views/applicationWays/purchaseOrderWay/index.vue')
      },
      {
        path: '/purchase_order_detail/:soId',
        name: 'PurchaseOrderDetail',
        meta: {
          title: '选择订单中的商品'
        },
        component: () => import('@/views/applicationWays/purchaseOrderWay/PurchaseOrderDetail.vue')
      },
      {
        path: '/inquire_serial_number',
        name: 'InquireSerialNumber',
        meta: {
          title: '产品序列号申请',
          navBarTitle: false
        },
        component: () => import('@/views/applicationWays/serialNumberWay/index.vue')
      },
      {
        path: '/commodity_information/:sn',
        name: 'CommodityInformation',
        meta: {
          title: '序列号查询结果'
        },
        component: () => import('@/views/applicationWays/serialNumberWay/CommodityInformation.vue')
      },
      {
        path: '/application_form/:id',
        name: 'ApplicationForm',
        meta: {
          title: '维修申请'
        },
        component: () => import('@/views/applicationForm/index.vue')
      },
      {
        path: '/fill_express_info',
        name: 'FillExpressInfo',
        meta: {
          title: '填写快递信息',
          navBar: false
        },
        component: () => import('@/views/fillExpressInfo/index.vue')
      },
      {
        path: '/repair_list',
        name: 'RepairList',
        meta: {
          title: '我的维修单'
        },
        component: () => import('@/views/myRepairOrder/repairList/index.vue')
      },
      {
        path: '/repair_detail/:id',
        name: 'RepairDetail',
        meta: {
          title: '维修单详情',
          navBarTitle: false
        },
        component: () => import('@/views/myRepairOrder/repairDetail/index.vue')
      },
      {
        path: '/apply_invoice/:id',
        name: 'ApplyInvoice',
        meta: {
          title: '申请开票'
        },
        component: () => import('@/views/invoice/applyInvoice/index.vue')
      },
      {
        path: '/invoice_result/:id',
        name: 'InvoiceResult',
        meta: {
          title: '完成'
        },
        component: () => import('@/views/invoice/invoiceResult/index.vue')
      },
      {
        path: '/payment/:id',
        name: 'Payment',
        meta: {
          title: '支付'
        },
        component: () => import('@/views/payment/index.vue')
      },
      {
        path: '/pay_result',
        name: 'PayResult',
        meta: {
          title: '支付结果'
        },
        component: () => import('@/views/payResult/index.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      navBarTitle: false
    },
    component: () => import('@/views/notFound/index.vue')
  }
]

// 解决路由重定向报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
