import Vue from 'vue'
import Vuex from 'vuex'
import { user } from '@/store/modules/user'
import { repair } from '@/store/modules/repair'
import { invoice } from '@/store/modules/invoice'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    repair,
    invoice
  }
})
