/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddressBaiduAIReply } from '../models';
// @ts-ignore
import { CommonStringRequest } from '../models';
// @ts-ignore
import { NlpBaseRequest } from '../models';
/**
 * BaiduAiApi - axios parameter creator
 * @export
 */
export const BaiduAiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 地址识别
         * @param {CommonStringRequest} [commonStringRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSdkBaiduAddressPost: async (commonStringRequest?: CommonStringRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sdk/baidu/Address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commonStringRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 图片-地址识别
         * @param {NlpBaseRequest} [nlpBaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSdkBaiduPictureAddressPost: async (nlpBaseRequest?: NlpBaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sdk/baidu/PictureAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nlpBaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 快递面单-地址识别
         * @param {NlpBaseRequest} [nlpBaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSdkBaiduWayBillAddressPost: async (nlpBaseRequest?: NlpBaseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sdk/baidu/WayBillAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nlpBaseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaiduAiApi - functional programming interface
 * @export
 */
export const BaiduAiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaiduAiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 地址识别
         * @param {CommonStringRequest} [commonStringRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSdkBaiduAddressPost(commonStringRequest?: CommonStringRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBaiduAIReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSdkBaiduAddressPost(commonStringRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 图片-地址识别
         * @param {NlpBaseRequest} [nlpBaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSdkBaiduPictureAddressPost(nlpBaseRequest?: NlpBaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBaiduAIReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSdkBaiduPictureAddressPost(nlpBaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 快递面单-地址识别
         * @param {NlpBaseRequest} [nlpBaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSdkBaiduWayBillAddressPost(nlpBaseRequest?: NlpBaseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBaiduAIReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSdkBaiduWayBillAddressPost(nlpBaseRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaiduAiApi - factory interface
 * @export
 */
export const BaiduAiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaiduAiApiFp(configuration)
    return {
        /**
         * 
         * @summary 地址识别
         * @param {CommonStringRequest} [commonStringRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSdkBaiduAddressPost(commonStringRequest?: CommonStringRequest, options?: any): AxiosPromise<AddressBaiduAIReply> {
            return localVarFp.apiSdkBaiduAddressPost(commonStringRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 图片-地址识别
         * @param {NlpBaseRequest} [nlpBaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSdkBaiduPictureAddressPost(nlpBaseRequest?: NlpBaseRequest, options?: any): AxiosPromise<AddressBaiduAIReply> {
            return localVarFp.apiSdkBaiduPictureAddressPost(nlpBaseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 快递面单-地址识别
         * @param {NlpBaseRequest} [nlpBaseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSdkBaiduWayBillAddressPost(nlpBaseRequest?: NlpBaseRequest, options?: any): AxiosPromise<AddressBaiduAIReply> {
            return localVarFp.apiSdkBaiduWayBillAddressPost(nlpBaseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaiduAiApi - object-oriented interface
 * @export
 * @class BaiduAiApi
 * @extends {BaseAPI}
 */
export class BaiduAiApi extends BaseAPI {
    /**
     * 
     * @summary 地址识别
     * @param {CommonStringRequest} [commonStringRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaiduAiApi
     */
    public apiSdkBaiduAddressPost(commonStringRequest?: CommonStringRequest, options?: AxiosRequestConfig) {
        return BaiduAiApiFp(this.configuration).apiSdkBaiduAddressPost(commonStringRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 图片-地址识别
     * @param {NlpBaseRequest} [nlpBaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaiduAiApi
     */
    public apiSdkBaiduPictureAddressPost(nlpBaseRequest?: NlpBaseRequest, options?: AxiosRequestConfig) {
        return BaiduAiApiFp(this.configuration).apiSdkBaiduPictureAddressPost(nlpBaseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 快递面单-地址识别
     * @param {NlpBaseRequest} [nlpBaseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaiduAiApi
     */
    public apiSdkBaiduWayBillAddressPost(nlpBaseRequest?: NlpBaseRequest, options?: AxiosRequestConfig) {
        return BaiduAiApiFp(this.configuration).apiSdkBaiduWayBillAddressPost(nlpBaseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
