import { init } from '@umengfe/apm'

export default function initUapm() {
  if (process.env.VUE_APP_MODE !== 'production') return
  init({
    pid: '6668fc51940d5a4c4969ccac',
    errorFilter: {
      mode: 'ignore',
      rules: [
        'ResizeObserver loop',
        'Script error',
        'Loading chunk',
        'i.$refs.tree',
        'unhandledrejection',
        'Network Error',
        'Request failed with status code 412',
        'expired'
      ]
    }
  })
}
