<template>
  <van-nav-bar
    :title="title"
    :placeholder="placeholder"
    :border="border"
    :fixed="fixed"
    :z-index="zIndex"
    :left-text="leftText"
    :right-text="rightText"
    :left-arrow="leftArrow"
    :safe-area-inset-top="safeAreaInsetTop"
    @click-left="onClickLeft"
    @click-right="onClickRight"
    v-if="show"
  >
    <template v-slot:title>
      <slot name="title" />
    </template>
    <template v-slot:left>
      <div class="left-wrap">
        <svg-icon icon-class="left_arrow" class-name="left-arrow" />
        <slot name="left" />
      </div>
    </template>
    <template v-slot:right>
      <slot name="right" />
    </template>
  </van-nav-bar>
  <!--  </div>-->
</template>

<script>
import { NavBar } from 'vant'
import Util from '@/utils/util'
import AppProtocol from '@/utils/appProtocol'

let clickTime
export default {
  name: 'PageNavBar',
  components: {
    [NavBar.name]: NavBar
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    leftArrow: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: [Number, String],
      default: 1
    },
    safeAreaInsetTop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: !Util.isInMiniProgram() && !Util.isInApp()
    }
  },
  created() {
    // 通过路由参数控制是否显示头部 优先级最高
    if (this.$route.query.showHeader === 'true') {
      this.show = true
    }
  },
  methods: {
    onClickLeft() {
      if (this.isFastClick(200)) return
      console.log('---onClickBackBtn---')
      console.log('---window.history.length---')
      console.log(window.history.length)

      if (typeof this.$listeners['click-left'] === 'function') {
        console.log('---clickLeft---')
        // 该回调会对返回事件进行拦截，替代组件内的返回逻辑
        this.$emit('click-left')
        return
      }

      if (this.$route.name === 'index' && Util.isInApp()) {
        AppProtocol.closeBrowser()
      } else {
        this.$router.go(-1)
      }
    },
    onClickRight() {
      this.$emit('click-right')
    },
    // 快速点击检测
    isFastClick(timeout) {
      let current = Date.now()
      if (!clickTime) {
        clickTime = current
      } else {
        let timeDiff = current - clickTime
        if (timeDiff < timeout) {
          console.warn('监测到快速点击！')
          return true
        } else {
          clickTime = current
        }
      }
      return false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar {
  background: transparent;

  .van-nav-bar__content {
    height: 94px;
  }
}

.left-wrap {
  display: flex;
  align-items: center;

  .left-arrow {
    width: 38px;
    height: 38px;
  }
}

/deep/ .van-nav-bar__title {
  font-size: 32px;
  line-height: 38px;
  color: @heading-color;
}
</style>
