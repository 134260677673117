/* tslint:disable */
/* eslint-disable */
/**
 * 维修系统
 * 维修系统:v0.0.1版本
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserAddressCreateReply } from '../models';
// @ts-ignore
import { UserAddressCreateRequest } from '../models';
// @ts-ignore
import { UserAddressDetailReply } from '../models';
// @ts-ignore
import { UserAddressPageReply } from '../models';
// @ts-ignore
import { UserAddressReplaceRequest } from '../models';
/**
 * UserAddressApi - axios parameter creator
 * @export
 */
export const UserAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 维修系统用户收货地址表列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/UserAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除维修系统用户收货地址表
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiUserAddressIdDelete', 'id', id)
            const localVarPath = `/custapi/UserAddress/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 维修系统用户收货地址表详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiUserAddressIdGet', 'id', id)
            const localVarPath = `/custapi/UserAddress/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 更新维修系统用户收货地址表
         * @param {number} id 
         * @param {UserAddressReplaceRequest} [userAddressReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressIdPut: async (id: number, userAddressReplaceRequest?: UserAddressReplaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('custapiUserAddressIdPut', 'id', id)
            const localVarPath = `/custapi/UserAddress/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAddressReplaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建维修系统用户收货地址表
         * @param {UserAddressCreateRequest} [userAddressCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressPost: async (userAddressCreateRequest?: UserAddressCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/UserAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAddressCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 维修系统用户收货地址表下拉框
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressSelectGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/custapi/UserAddress/Select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }

            if (isResourceAuthorization !== undefined) {
                localVarQueryParameter['IsResourceAuthorization'] = isResourceAuthorization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAddressApi - functional programming interface
 * @export
 */
export const UserAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 维修系统用户收货地址表列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiUserAddressGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAddressPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiUserAddressGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除维修系统用户收货地址表
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiUserAddressIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiUserAddressIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 维修系统用户收货地址表详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiUserAddressIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAddressDetailReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiUserAddressIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 更新维修系统用户收货地址表
         * @param {number} id 
         * @param {UserAddressReplaceRequest} [userAddressReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiUserAddressIdPut(id: number, userAddressReplaceRequest?: UserAddressReplaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiUserAddressIdPut(id, userAddressReplaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建维修系统用户收货地址表
         * @param {UserAddressCreateRequest} [userAddressCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiUserAddressPost(userAddressCreateRequest?: UserAddressCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAddressCreateReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiUserAddressPost(userAddressCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 维修系统用户收货地址表下拉框
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async custapiUserAddressSelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.custapiUserAddressSelectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAddressApi - factory interface
 * @export
 */
export const UserAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAddressApiFp(configuration)
    return {
        /**
         * 
         * @summary 维修系统用户收货地址表列表
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<UserAddressPageReply>> {
            return localVarFp.custapiUserAddressGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除维修系统用户收货地址表
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.custapiUserAddressIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 维修系统用户收货地址表详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressIdGet(id: number, options?: any): AxiosPromise<UserAddressDetailReply> {
            return localVarFp.custapiUserAddressIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 更新维修系统用户收货地址表
         * @param {number} id 
         * @param {UserAddressReplaceRequest} [userAddressReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressIdPut(id: number, userAddressReplaceRequest?: UserAddressReplaceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.custapiUserAddressIdPut(id, userAddressReplaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建维修系统用户收货地址表
         * @param {UserAddressCreateRequest} [userAddressCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressPost(userAddressCreateRequest?: UserAddressCreateRequest, options?: any): AxiosPromise<UserAddressCreateReply> {
            return localVarFp.custapiUserAddressPost(userAddressCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 维修系统用户收货地址表下拉框
         * @param {number} [pageNo] 页码
         * @param {number} [pageSize] 页大小
         * @param {string} [q] 筛选条件
         * @param {string} [orderBy] 排序条件
         * @param {string} [fields] 展示字段
         * @param {number} [isResourceAuthorization] 是否资源鉴权
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        custapiUserAddressSelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.custapiUserAddressSelectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAddressApi - object-oriented interface
 * @export
 * @class UserAddressApi
 * @extends {BaseAPI}
 */
export class UserAddressApi extends BaseAPI {
    /**
     * 
     * @summary 维修系统用户收货地址表列表
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressApi
     */
    public custapiUserAddressGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return UserAddressApiFp(this.configuration).custapiUserAddressGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除维修系统用户收货地址表
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressApi
     */
    public custapiUserAddressIdDelete(id: number, options?: AxiosRequestConfig) {
        return UserAddressApiFp(this.configuration).custapiUserAddressIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 维修系统用户收货地址表详情
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressApi
     */
    public custapiUserAddressIdGet(id: number, options?: AxiosRequestConfig) {
        return UserAddressApiFp(this.configuration).custapiUserAddressIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 更新维修系统用户收货地址表
     * @param {number} id 
     * @param {UserAddressReplaceRequest} [userAddressReplaceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressApi
     */
    public custapiUserAddressIdPut(id: number, userAddressReplaceRequest?: UserAddressReplaceRequest, options?: AxiosRequestConfig) {
        return UserAddressApiFp(this.configuration).custapiUserAddressIdPut(id, userAddressReplaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建维修系统用户收货地址表
     * @param {UserAddressCreateRequest} [userAddressCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressApi
     */
    public custapiUserAddressPost(userAddressCreateRequest?: UserAddressCreateRequest, options?: AxiosRequestConfig) {
        return UserAddressApiFp(this.configuration).custapiUserAddressPost(userAddressCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 维修系统用户收货地址表下拉框
     * @param {number} [pageNo] 页码
     * @param {number} [pageSize] 页大小
     * @param {string} [q] 筛选条件
     * @param {string} [orderBy] 排序条件
     * @param {string} [fields] 展示字段
     * @param {number} [isResourceAuthorization] 是否资源鉴权
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAddressApi
     */
    public custapiUserAddressSelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, isResourceAuthorization?: number, options?: AxiosRequestConfig) {
        return UserAddressApiFp(this.configuration).custapiUserAddressSelectGet(pageNo, pageSize, q, orderBy, fields, isResourceAuthorization, options).then((request) => request(this.axios, this.basePath));
    }
}
