import api from '@/utils/api'

export const repair = {
  state: {
    listData: [],
    scrollTop: 0
  },
  mutations: {
    SET_LIST_DATA: (state, list) => {
      state.listData = list
    },
    SET_SCROLL_TOP: (state, scrollTop) => {
      state.scrollTop = scrollTop
    },
    UPDATE_REPAIR_DATA: (state, data) => {
      const result = state.listData.find(item => item.id === data.id)
      if (result) {
        result.statusName = data.statusName
        result.status = data.status
        result.receiveExpressNumber = data.receiveExpressNumber
        result.sendExpressNumber = data.sendExpressNumber
        result.receiveLogisticsName = data.receiveLogisticsName
        result.sendLogisticsName = data.sendLogisticsName
        // 如果存在维修方案时 更新额外字段
        if (data.repairPlanInfoList.length) {
          result.repairPrice = data.repairPlanInfoList[0].price
          result.isNeedPay = data.repairPlanInfoList[0].isNeedPay
        }
      }
    },
    CLEAR_REPAIR_DATA: state => {
      state.listData = []
      state.scrollTop = 0
    }
  },
  actions: {
    updateRepairData({ commit }, id) {
      if (!id) return
      api.custapiRepairTicketIdGet(id).then(res => {
        commit('UPDATE_REPAIR_DATA', res)
      })
    }
  }
}
