import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './router/hook'
import store from './store'
import PageNavBar from './components/PageNavBar/index.vue'

// 引入uapm 性能分析
import initUapm from './utils/uapm'

import { Button, Dialog, Lazyload, Toast } from 'vant'
import './theme/bb-theme.less'
import './theme/normalize.css'
import Vconsole from 'vconsole' //  引入svg
import SvgIcon from './components/SvgIcon/index.vue' // svg component

const req = require.context('@/assets/icons/svg', false, /\.svg$/)
req.keys().map(req)

Vue.use(Toast)
Vue.use(Button)
Vue.use(Dialog)
Vue.component('PageNavBar', PageNavBar)
Vue.component('svg-icon', SvgIcon)

Vue.use(Lazyload, {
  loading: require('./assets/placeholder.jpg'),
  error: require('./assets/default_picture.png')
})

// 事件总线
Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_MODE === 'testing') {
  new Vconsole()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

initUapm()

// 解决ios safari浏览器页面返回不刷新问题
;(function () {
  let isPageHide = false
  window.addEventListener('pageshow', function () {
    if (isPageHide) {
      document.body.innerHTML = ''
      window.location.reload()
    }
  })
  window.addEventListener('pagehide', function () {
    isPageHide = true
  })
})()
