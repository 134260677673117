export const invoice = {
  state: {
    id: '',
    invoiceData: null
  },
  mutations: {
    SET_INVOICE_ID: (state, id) => {
      state.id = id
    },
    SET_INVOICE_DATA: (state, data) => {
      state.invoiceData = data
    },
    CLEAR_INVOICE_DATA: state => {
      state.id = ''
      state.invoiceData = null
    }
  }
}
